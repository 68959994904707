<dx-data-grid #serviceCreditMemoDataGridRef id="gridContainer" [dataSource]="serviceCreditMemosDataSource"
	keyExpr="guid" [wordWrapEnabled]="true" [hoverStateEnabled]="true" [showBorders]="true" [showColumnLines]="true"
	[showRowLines]="false" [allowColumnResizing]="true" [filterSyncEnabled]="true" [filterValue]="filterValue"
	[remoteOperations]="false" [columnAutoWidth]="true" [allowColumnReordering]="true" [width]="'100%'"
	(onCellClick)="doOnCrMemoItemCellClick($event)" (onToolbarPreparing)="doServiceCreditMemoToolbarPreparing($event)"
	class="cellColor hoverClr hideFilterPanelInx">
	<dxo-scrolling [scrollByThumb]="true" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>
	<dxo-paging [pageSize]="pageOptions.pageSize"></dxo-paging>
	<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="pageOptions.pageSizeOptions"
		[showInfo]="true">
	</dxo-pager>
	<dxo-filter-row [visible]="true"></dxo-filter-row>
	<dxo-filter-panel [visible]="true"></dxo-filter-panel>
	<dxo-group-panel [visible]="true"></dxo-group-panel>
	<dxo-header-filter [visible]="false"></dxo-header-filter>
	<dxo-column-chooser [enabled]="true"></dxo-column-chooser>
	<dxo-state-storing [enabled]="true" type="custom" [customLoad]="doLoadServiceCreditMemoState"
		[customSave]="doSaveServiceCreditMemoState" [storageKey]="stateStorageKey">
	</dxo-state-storing>
	<div *dxTemplate="let data of 'templateFilterText'">
		<div (click)="doOpenFilterBuilder()" class="highlight mrgR10 pointerMark">
			<span>{{filterText}}</span>
		</div>
	</div>

	<div *dxTemplate="let data of 'templateActionBtns'">
		<dx-drop-down-button #dropDownButtonRef [splitButton]="false" [useSelectMode]="false" [showArrowIcon]="false"
			text="Action" class="secondaryBtn actionTextWithIconBtnIx actionBtnToolbarIx" [items]="listOfActions"
			[wrapItemText]="true" [stylingMode]="'outlined'" displayExpr="btn_name" keyExpr="btn_code" icon="spindown"
			(onButtonClick)="doActionsBtnClick($event)" (onItemClick)="doActionsBtnItemClick($event)"
			[dropDownOptions]="{ width: 230 }"></dx-drop-down-button>
	</div>

	<dxi-column dataField="clientName" dataType="string" caption="Customer Name" [width]="'auto'"></dxi-column>

	<dxi-column dataField="docnum" dataType="string" caption="Doc. Num" [width]="'auto'"
		cellTemplate="cellTemplateDocNo">
		<div *dxTemplate="let element of 'cellTemplateDocNo'">
			<span class="highlight pointerMark">{{element.data.docnum}}</span>
		</div>
	</dxi-column>
	<dxi-column dataField="docdate" dataType="date" caption="Doc. Date" [width]="'auto'">
	</dxi-column>
	<dxi-column dataField="doctotal" dataType="number" caption="Doc. Total" [width]="'auto'" [format]="currencyFormat">
	</dxi-column>
	<dxo-summary>
		<dxi-total-item column="doctotal" summaryType="sum" displayFormat="{0}">
			<dxo-value-format type="currency" [precision]="decimalPointForCurrency"> </dxo-value-format>
		</dxi-total-item>
	</dxo-summary>
</dx-data-grid>